import React, { Fragment } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"

import * as style from './style/index.module.scss'

import Layout from "../components/layout"
import SEO from "../components/seo"

import GridLayout, { GridItem } from '../components/utils/GridLayout'

const allProjects = graphql`
  query {
    allProjectsYaml {
      edges {
        node {
          id
          name
          client
          author
          original {
            publicURL
            name
          }
          images {
            publicURL
            name
            mobileImage: childImageSharp {
              fluid(maxWidth: 525, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
            desktopImage: childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`

const GRID_ONE_THIRD = {col: { md: 4, lg: 4, sm: 4, xs: 4 }};
const GRID_TWO_THIRD = {col: { md: 8, lg: 8, sm: 8, xs: 8 }};

const GRID_SIZE = 12;
// Series limit
const LIMIT = 3;

/**
 * Project thumb / link
 * @param {[type]} options.name      [description]
 * @param {[type]} options.client    [description]
 * @param {[type]} options.author    [description]
 */
function Project({name, client, author, mobileImage, desktopImage}) {
  const alt = `${author} for ${client}, makeup by Saraï Fiszel`;

  const imgOptions = {
    objectFit: 'cover',
    objectPosition: 'top center',
    alt: alt,
    fluid: [
      mobileImage.fluid,
      {
        ...desktopImage.fluid,
        media: `(min-width: 768px)`,
      }
    ],
    style: {
      position: 'absolute',
      width: '100%',
      height: '100%',
    },
  };

  return (
    <Link to = { `/project/${name}` }>
      <div 
        itemProp = 'itemListElement'
        itemScope
        itemType = 'http://schema.org/ImageObject'
        className = 'img-sqr'>
        <meta 
          itemProp = 'description'
          content = { alt } />
        <Img
          itemProp = 'contentUrl'
          { ...imgOptions } />

        <div className = { style.projectInfo }>
          <div><h2>{ client }</h2>
          <p itemProp = 'author'>{ author }</p></div>
        </div>
      </div>
    </Link>
  )
}


/**
 * Random sorting
 * @return {Number} Floating number between -0.5 and 0.5
 */
function randomize() {
  return Math.random() - 0.5;
}

function IndexPage() {
  const { allProjectsYaml: {edges} } = useStaticQuery(allProjects);

  const allImages = edges.reduce((all, {node: {images, original, name, client, author}}) => {
    if ( !images ) return all;
    return all.concat(images.map((image) => {
      return {...image, name, client, author}
    }));
  }, []).sort(randomize).slice(0, LIMIT * GRID_SIZE);

  const laps = parseInt(allImages.length / GRID_SIZE);
  const circus = new Array(laps).fill();

  return (
    <Layout isHome = { true }>
      <SEO title="Work" />
      <div 
        id = "ProjectGrid" 
        className = 'wrapper'
        itemScope
        itemType = 'http://schema.org/ItemList'>

        <meta 
          itemProp = "name"
          content = { `Saraï Fiszel's work as a makeup artist` } />

        <GridLayout>

          { circus.map((_,lap) => (
            <Fragment key = { lap }>
              { allImages
              .slice(0 + lap * GRID_SIZE, 3 + lap * GRID_SIZE)
              .map((project, index) => {

                let grid = GRID_ONE_THIRD;
                let { id } = project;

                return (
                  <GridItem 
                    key = { index }
                    grid = { grid }
                    className = { 'grid__item ' + style.gridImage }>
                    <Project { ...project } />
                  </GridItem>
                )
              }) }

              <GridItem 
                grid = { GRID_TWO_THIRD }
                className = { 'grid__item ' + style.gridImage }>
                <Project { ...allImages[3 + lap * GRID_SIZE] } />
              </GridItem>
                
              { allImages
              .slice(4 + lap * GRID_SIZE, 6 + lap * GRID_SIZE)
              .map((project, index) => {

                let grid = GRID_ONE_THIRD;
                let { id } = project;

                return (
                  <GridItem 
                    key = { index }
                    grid = { grid }
                    className = { 'grid__item ' + style.gridImage }>
                    <Project { ...project } />
                  </GridItem>
                )
              }) }

              <GridItem grid = { GRID_ONE_THIRD }>
                <GridLayout>
                  { allImages
                  .slice(6 + lap * GRID_SIZE, 8 + lap * GRID_SIZE)
                  .map((project, index) => {

                    let { id } = project;

                    return (
                      <GridItem 
                        key = { index }
                        className = { 'grid__item ' + style.gridImage }>
                        <Project { ...project } />
                      </GridItem>
                    )
                  }) }
                </GridLayout>
              </GridItem>

              <GridItem 
                grid = { GRID_TWO_THIRD }
                className = { 'grid__item ' + style.gridImage }>
                <Project { ...allImages[8 + lap * GRID_SIZE] } />
              </GridItem>

              { allImages
              .slice(9 + lap * GRID_SIZE, 12 + lap * GRID_SIZE)
              .map((project, index) => {

                let grid = GRID_ONE_THIRD;

                return (
                  <GridItem
                    key = { index } 
                    grid = { grid }
                    className = { 'grid__item ' + style.gridImage }>
                    <Project { ...project } />
                  </GridItem>
                )
              }) }
            </Fragment>
          )) }

        </GridLayout>
      </div>
    </Layout>
  )
}


export default IndexPage
